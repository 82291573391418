import React, { useState } from 'react';
import axios from 'axios';
import './ChangePasswordModal.css'; // Optional: Add your custom CSS

const ChangePasswordModal = ({ userId, onClose }) => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState(''); // Success/Error message state

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    // Check if passwords match
    if (password !== confirmPassword) {
      setMessage('Passwords do not match.');
      setIsSubmitting(false);
      return;
    }

    try {
      const response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/users/${userId}/change-password`, {
        password,
      });

      if (response.status === 200) {
        setMessage('Password updated successfully!');
        setPassword(''); // Clear the form after success
        setConfirmPassword(''); // Clear the confirm password
      }
    } catch (error) {
      setMessage('Failed to update password. Please try again.');
    } finally {
      setIsSubmitting(false);
      setTimeout(() => setMessage(''), 5000); // Hide the message after 5 seconds
    }
  };

  return (
    <div className="modal-backdrop">
      <div className="modal-content">
        <h2>Change Password</h2>
        
        {message && <div className={message.includes('successfully') ? 'success-message' : 'error-message'}>
          {message}
        </div>}
        
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="password">New Password</label>
            <input
              type="password"
              id="password"
              name="password"
              value={password}
              onChange={handlePasswordChange}
              required
              placeholder="Enter new password"
            />
          </div>

          <div className="form-group">
            <label htmlFor="confirm-password">Confirm Password</label>
            <input
              type="password"
              id="confirm-password"
              name="confirm-password"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              required
              placeholder="Confirm new password"
            />
          </div>

          <div className="modal-actions">
            <button type="submit" className="save-btn" disabled={isSubmitting}>
              {isSubmitting ? 'Updating...' : 'Save Changes'}
            </button>
            <button type="button" className="cancel-btn" onClick={onClose} disabled={isSubmitting}>
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChangePasswordModal;
