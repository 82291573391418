// import { useEffect } from 'react';
// import socket from './socket'; // Make sure this is your centralized socket instance
// import { useNotificationContext } from './NotificationContext';

// const NotificationListener = () => {
//     const { addNotification } = useNotificationContext();

//     useEffect(() => {
//         // Use local storage or a state value for userId; here we assume `userId = 1` for testing
//         const userId = localStorage.getItem('userId') || 1;

//         // Join the correct room for the user
//         if (userId) {
//             socket.emit('joinRoom', `user_${userId}`);
//             console.log(`Joined room user_${userId}`);
//         }

//         // Listen for notifications
//         socket.on('notification', (data) => {
//             console.log('Received notification:', data.message);
//             addNotification(data.message);
//         });

//         // Connection events for debugging
//         socket.on('connect', () => {
//             console.log('Connected to WebSocket server');
//         });

//         socket.on('disconnect', () => {
//             console.log('Disconnected from WebSocket server');
//         });

//         socket.on('connect_error', (error) => {
//             console.error('Connection error:', error);
//         });

//         return () => {
//             socket.off('notification');
//             socket.off('connect');
//             socket.off('disconnect');
//             socket.off('connect_error');
//         };
//     }, [addNotification]);

//     return null;
// };

// export default NotificationListener;
import { useEffect } from 'react';
import socket from './socket';
import { useNotificationContext } from './NotificationContext';

const NotificationListener = () => {
    const { addNotification } = useNotificationContext();

    useEffect(() => {
        const userId = localStorage.getItem('user_id');
        const role = localStorage.getItem('role');

        if (userId && role) {
            const room = role === 'admin' ? `admin_${userId}` : `user_${userId}`;
            socket.emit('joinRoom', room);
            console.log(`Joined room ${room}`);
        }

        socket.on('notification', (data) => {
            console.log('Received notification:', data);
            addNotification(data); // Pass the notification to the context
        });

        socket.on('connect', () => {
            console.log('Connected to WebSocket server');
        });

        socket.on('disconnect', () => {
            console.log('Disconnected from WebSocket server');
        });

        return () => {
            socket.off('notification');
            socket.off('connect');
            socket.off('disconnect');
        };
    }, [addNotification]);

    return null;
};

export default NotificationListener;
