import React, { useState } from 'react';
import axios from 'axios';
import './CommentModal.css'; // Optional: Add your CSS for modal styling

const CommentModal = ({ leadId, onClose, onCommentAdded }) => {
  const [comment, setComment] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      // Call API to add comment to the lead
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/leads/${leadId}/comments`, { comment });
      onCommentAdded(); // Refresh leads after adding comment
      onClose(); // Close modal
    } catch (error) {
      console.error('Error adding comment:', error);
    }
  };

  return (
    <div className="modal-backdrop">
      <div className="modal-content">
        <h2>Add Comment</h2>
        <form onSubmit={handleSubmit}>
          <textarea
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            placeholder="Enter your comment"
            required
          />
          <div className="modal-actions">
            <button type="submit">Submit</button>
            <button type="button" onClick={onClose}>Cancel</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CommentModal;
