import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './ViewHistoryModal.css'; // Optional: Add custom styling for the modal

const ViewHistoryModal = ({ leadId, onClose }) => {
  const [history, setHistory] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchHistory = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/leads/${leadId}/history`);
        
        console.log('History API response:', response.data); // Log the response to confirm data
        setHistory(response.data);
      } catch (error) {
        console.error('Error fetching history:', error);
        setError('Failed to load history. Please try again.');
      }
    };

    fetchHistory();
  }, [leadId]);

  return (
    <div className="modal-backdrop">
      <div className="modal-content">
        <h2>Assignment History</h2>
        {error && <p className="error-message">{error}</p>}
        <ul className="history-list">
          {history.length > 0 ? (
            history.map((entry, index) => (
              <li key={index}>
                <span>
                  {entry.assigned_role === 'unassigned' ? (
                    // Unassignment case: show who unassigned and from whom
                    entry.assigner_role === 'admin'
                      ? `${entry.assigner} has unassigned this lead from ${entry.assignee ? entry.assignee : 'unknown user'} `
                      : entry.assigner_role === 'user'
                      ? `${entry.assigner} has unassigned this lead from ${entry.assignee ? entry.assignee : 'unknown user'} `
                      : entry.assigner_role === 'superuser'
                      ? `${entry.assigner} has unassigned this lead from ${entry.assignee ? entry.assignee : 'unknown user'} `
                      : `${entry.assigner} (Support) has unassigned this lead from ${entry.assignee ? entry.assignee : 'unknown user'} `
                  ) : (
                    // Assignment case: show who assigned and to whom
                    entry.assigner_role === 'admin'
                      ? `${entry.assigner} has assigned this lead to ${entry.assignee} `
                      : entry.assigner_role === 'user'
                      ? `${entry.assigner} has assigned this lead to ${entry.assignee} `
                      : entry.assigner_role === 'superuser'
                      ? `${entry.assigner} has assigned this lead to ${entry.assignee} `
                      : `${entry.assigner} (Support) has assigned this lead to ${entry.assignee} `
                  )}
                   on {new Date(entry.assigned_at).toLocaleString()}
                </span>
              </li>
            ))
          ) : (
            <p>No assignment history found.</p>
          )}
        </ul>
        <div className="modal-actions">
          <button onClick={onClose}>Close</button>
        </div>
      </div>
    </div>
  );
};

export default ViewHistoryModal;
