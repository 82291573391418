import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
    Drawer, DrawerBody, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton,
    Button, useDisclosure, Menu, MenuButton, MenuList, MenuItem
} from '@chakra-ui/react';
import { HamburgerIcon, ChevronDownIcon } from '@chakra-ui/icons';
import './Navbar.css';
import logo from "../../assets/FinalRedBG.svg";
import axios from 'axios'; // Assuming axios is used for API calls
import { useNotificationContext } from '../Notification/NotificationContext';

const Navbar = ({ role }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [username, setUsername] = useState('');
    const { clearNotifications } = useNotificationContext();

    useEffect(() => {
        const fetchUsername = async () => {
            try {
                const userId = localStorage.getItem('user_id');  // Assuming you have userId in localStorage
                console.log(userId);
                if (userId) {
                    // Make a unified API call to fetch user details based on role and userId
                    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/user/details`, {
                        params: { userId, role }
                    });

                    // Assuming the response contains a `username` field
                    setUsername(response.data.username);
                }
            } catch (error) {
                console.error('Error fetching username:', error);
            }
        };

        fetchUsername();
    }, [role]);

    const handleLinkClick = () => {
        onClose();
        // clearNotifications();
    };

    return (
        <nav className="navbar">
            <div className="navbar-brand">
                <Link to="/">
                    <img src={logo} alt="Mentor Leads Logo" className="responsive-logo" />
                </Link>
                <h2 className="title">Mentor Leads</h2>
            </div>

            {/* Desktop Navigation */}
            <ul className="nav-links">
                {role === 'admin' && (
                    <>
                        <li><Link to="/admin-dashboard">Home</Link></li>
                        <li><Link to="/add-leads">Add Leads</Link></li>
                        <li><Link to="/manage-users">Add Users</Link></li>
                        <li><Link to="/manage">Manage Users</Link></li>
                        <li><Link to="/admin-assigned-leads">View All Assigned Leads</Link></li>
                        <li className="dropdown">
                            <button className="dropbtn">Task</button>
                            <div className="dropdown-content">
                                <Link to="/task-panel">Task Panel</Link>
                                {/* <li><Link to="/notifications">View Notifications</Link></li> */}
                                {/* <Link to="/task-panel-for-users">Task Panel for Users</Link> */}
                            </div>
                        </li>
                    </>
                )}
                {role === 'user' && (
                    <>
                        <li><Link to="/user-dashboard">Home</Link></li>
                        <li><Link to="/add-leads">Add Leads</Link></li>
                        <li><Link to="/task-panel">Task Panel</Link></li>
                        <li><Link to="/notifications">View Notifications</Link></li>
                        <li><Link to="/admin-assigned-leads">View All Assigned Leads</Link></li>
                    </>
                )}
                {role === 'support' && (
                    <>
                        <li><Link to="/support-dashboard">Home</Link></li> {/* Show Leads Page as Home */}
                        <li><Link to="/add-leads">Add Leads</Link></li>
                    </>
                )}
                {role === 'superuser' && (
                    <>
                        <li><Link to="/superuser-dashboard">Home</Link></li> {/* Show Leads Page as Home */}
                        <li><Link to="/add-leads">Add Leads</Link></li>
                        <li><Link to="/manage-users">Add Users</Link></li>
                        <li><Link to="/manage">Manage Users</Link></li>
                    </>
                )}
                <li className="login-btn">
                    <Menu>
                        <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                            Hello, {username || 'Guest'}
                        </MenuButton>
                        <MenuList>
                            <MenuItem>
                                <Link to="/" onClick={handleLinkClick}>Logout</Link>
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </li>
            </ul>

            {/* Hamburger icon to open the main drawer for mobile */}
            <Button variant="unstyled" className="menu-icon" onClick={onOpen}>
                <HamburgerIcon w={8} h={9} />
            </Button>

            {/* Drawer for Mobile */}
            <Drawer placement="right" onClose={onClose} isOpen={isOpen}>
                <DrawerOverlay />
                <DrawerContent className="drawer-content">
                    <DrawerCloseButton />
                    <DrawerHeader>Main Menu</DrawerHeader>
                    <DrawerBody>
                        <ul className="drawer-nav">
                            {role === 'admin' && (
                                <>
                                    <li><Link to="/admin-dashboard">Home</Link></li>
                                    <li><Link to="/add-leads">Add Leads</Link></li>
                                    <li><Link to="/manage-users">Add Users</Link></li>
                                    <li><Link to="/manage">Manage Users</Link></li>
                                    <li><Link to="/admin-assigned-leads">View All Assigned Leads</Link></li>
                                    <li className="dropdown">
                                        <button className="dropbtn">Task</button>
                                        <div className="dropdown-content">
                                            <Link to="/task-panel">Task Panel</Link>
                                            {/* <li><Link to="/notifications">View Notifications</Link></li> */}
                                            {/* <Link to="/task-panel-for-users">Task Panel for Users</Link> */}
                                        </div>
                                    </li>
                                </>
                            )}
                            {role === 'user' && (
                                <>
                                    <li><Link to="/user-dashboard">Home</Link></li>
                                    <li><Link to="/add-leads">Add Leads</Link></li>
                                    <li><Link to="/task-panel">Task Panel</Link></li>
                                    <li><Link to="/notifications">View Notifications</Link></li>
                                    <li><Link to="/admin-assigned-leads">View All Assigned Leads</Link></li>
                                </>
                            )}
                            {role === 'support' && (
                                <>
                                    <li><Link to="/support-dashboard" onClick={handleLinkClick}>Home</Link></li>
                                    <li><Link to="/add-leads" onClick={handleLinkClick}>Add Leads</Link></li>
                                </>
                            )}
                            {role === 'superuser' && (
                                <>
                                    <li><Link to="/superuser-dashboard">Home</Link></li> {/* Show Leads Page as Home */}
                                    <li><Link to="/add-leads">Add Leads</Link></li>
                                    <li><Link to="/manage-users">Add Users</Link></li>
                                    <li><Link to="/manage">Manage Users</Link></li>
                                </>
                            )}
                            <li>
                                <Menu>
                                    <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                                        Hello, {username || 'Guest'}
                                    </MenuButton>
                                    <MenuList>
                                        <MenuItem>
                                            <Link to="/" onClick={handleLinkClick}>Logout</Link>
                                        </MenuItem>
                                    </MenuList>
                                </Menu>
                            </li>
                        </ul>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </nav>
    );
};

export default Navbar;
