import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios'; // Axios for API requests
import './LoginPage.css';
import logo from '../../assets/FinalRedBG.svg';

const LoginPage = ({ setRole }) => {
  const { role } = useParams();
  const navigate = useNavigate();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const response = await axios.post(`${backendUrl}/api/login`, {
        username,
        password,
        role,
      });

      if (response.status === 200) {
        const { user_id } = response.data;
        // Store the role in localStorage
        localStorage.setItem('role', role);
        localStorage.setItem('user_id', user_id); 
        
        // Set the role in App component
        setRole(role);

        // Redirect based on role
        if (role === 'admin') {
          navigate('/admin-dashboard');
        } else if (role === 'support') {
          navigate('/support-dashboard');
        } else if (role === 'user'){
          navigate('/user-dashboard');
        }
        else{
          navigate('/superuser-dashboard');
        }
      }
    } catch (error) {
      setErrorMessage('Invalid credentials, please try again.');
      setUsername('');
      setPassword('');
      setTimeout(() => {
        setErrorMessage('');
      }, 3000);
    }
  };

  return (
    <div className="login-page">
      <div className="back-arrow" onClick={() => navigate('/')}>
        ←
      </div>

      <header className="header">
        <div className="logo-title">
          <img src={logo} alt="Mentor Leads Logo" className="logo" />
          <h1 className="title">Mentor Leads</h1>
        </div>
      </header>

      <div className="login-box">
        <h2>
          <span className="role">{role.charAt(0).toUpperCase() + role.slice(1)}</span>
          <span className="login-text">Login</span>
        </h2>
        <p>Please log in to your account</p>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="username">Username</label>
            <input
              type="text"
              id="username"
              name="username"
              placeholder="Enter username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              name="password"
              placeholder="Enter password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          {errorMessage && (
            <p className="error-message">{errorMessage}</p>
          )}
          <button type="submit" className="login-button">Login</button>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
