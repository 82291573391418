import React, { useEffect, useState } from 'react';
import axios from 'axios';
import CommentModal from '../CommentModal/CommentModal';
import AssignUserModal from '../AssignUserModal/AssignUserModal';
import ViewHistoryModal from '../ViewHistoryModal/ViewHistoryModal';
import ViewCommentsModal from '../ViewCommentsModal/ViewCommentsModal';
import './AdminDashboard.css';
import { InputGroup, Input, InputRightElement, Button } from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import EditLeadModal from '../EditLeadsModal/EditLeadsModal';

const AdminDashboard = () => {
  const [leads, setLeads] = useState([]);
  const [filteredLeads, setFilteredLeads] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const leadsPerPage = 15;
  const [selectedLeadId, setSelectedLeadId] = useState(null);
  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
  const [isCommentsModalOpen, setIsCommentsModalOpen] = useState(false);
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
  const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [expandedLeads, setExpandedLeads] = useState({});
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedLeadData, setSelectedLeadData] = useState(null);

  // State for assigned user filter
  const [assignedUser, setAssignedUser] = useState('all');
  const [assignedUsers, setAssignedUsers] = useState([]); // Dropdown options for assigned users

  useEffect(() => {
    fetchLeads();
  }, []);

  const fetchLeads = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/leads`);
      setLeads(response.data);
      setFilteredLeads(response.data);
      extractAssignedUsers(response.data); // Populate dropdown options
    } catch (error) {
      console.error('Error fetching leads:', error);
    }
  };

  // Extract unique assigned users for the dropdown
  const extractAssignedUsers = (leads) => {
    const users = leads
      .filter((lead) => lead.assigned)
      .map((lead) => lead.assigned_username)
      .filter((value, index, self) => self.indexOf(value) === index);
    setAssignedUsers(users);
  };

  const handleEditClick = (lead) => {
    setSelectedLeadData(lead);
    setSelectedLeadId(lead.id);
    setIsEditModalOpen(true);
  };

  const handleEditModalClose = () => {
    setIsEditModalOpen(false);
  };

  const handleEditModalSave = () => {
    fetchLeads();
    setIsEditModalOpen(false);
  };

  const handleSearch = () => {
    const query = searchQuery.toLowerCase();
    const filtered = leads.filter((lead) => {
      const matchesUserFilter =
        assignedUser === 'all' || (lead.assigned && lead.assigned_username === assignedUser);

      return (
        matchesUserFilter &&
        ((lead.name && lead.name.toLowerCase().includes(query)) ||
          (lead.email && lead.email.toLowerCase().includes(query)) ||
          (lead.phone && lead.phone.toLowerCase().includes(query)))
      );
    });
    setFilteredLeads(filtered);
    setCurrentPage(1);
  };

  useEffect(() => {
    if (searchQuery === '' && assignedUser === 'all') {
      setFilteredLeads(leads);
    } else {
      handleSearch();
    }
  }, [searchQuery, leads, assignedUser]);

  const indexOfLastLead = currentPage * leadsPerPage;
  const indexOfFirstLead = indexOfLastLead - leadsPerPage;
  const currentLeads = filteredLeads.slice(indexOfFirstLead, indexOfLastLead);
  const totalPages = Math.ceil(filteredLeads.length / leadsPerPage);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleCommentClick = (leadId) => {
    setSelectedLeadId(leadId);
    setIsCommentModalOpen(true);
  };

  const handleViewCommentsClick = (leadId) => {
    setSelectedLeadId(leadId);
    setIsCommentsModalOpen(true);
  };

  const handleAssignClick = (leadId) => {
    setSelectedLeadId(leadId);
    setIsAssignModalOpen(true);
  };

  const handleViewHistoryClick = (leadId) => {
    setSelectedLeadId(leadId);
    setIsHistoryModalOpen(true);
  };

  const handleCommentModalClose = () => {
    setIsCommentModalOpen(false);
  };

  const handleCommentsModalClose = () => {
    setIsCommentsModalOpen(false);
  };

  const handleAssignModalClose = () => {
    setIsAssignModalOpen(false);
  };

  const handleHistoryModalClose = () => {
    setIsHistoryModalOpen(false);
  };

  const handleLeadsRefresh = () => {
    fetchLeads();
  };

  const handleAssignUser = () => {
    handleLeadsRefresh();
  };

  const toggleLeadDetails = (leadId) => {
    setExpandedLeads((prev) => ({
      ...prev,
      [leadId]: !prev[leadId],
    }));
  };

  const renderPagination = () => {
    const pageNumbers = [];
    const totalNumbers = 5;

    if (totalPages > totalNumbers + 2) {
      const startPage = Math.max(2, currentPage - 2);
      const endPage = Math.min(totalPages - 1, currentPage + 2);

      pageNumbers.push(1);

      if (startPage > 2) {
        pageNumbers.push('...');
      }

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }

      if (endPage < totalPages - 1) {
        pageNumbers.push('...');
      }

      pageNumbers.push(totalPages);
    } else {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    }

    return pageNumbers.map((number, index) => (
      <button
        key={index}
        className={`pagination-btn ${currentPage === number ? 'active' : ''}`}
        onClick={() => paginate(number)}
        disabled={number === '...'}
      >
        {number}
      </button>
    ));
  };

  return (
    <div className="dashboard-content">
      {/* Title, Search Bar, and Assigned User Dropdown */}
      <div className="header-container">
        <h2>Leads Insights</h2>
        <InputGroup size="lg" className="search-bar" style={{ marginLeft: '20px' }}>
          <Input
            pr="4.5rem"
            type="text"
            placeholder="Search leads..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyDown={(e) => e.key === 'Enter' && handleSearch()}
          />
          <InputRightElement width="4.5rem">
            <Button h="1.75rem" size="sm" onClick={handleSearch}>
              <SearchIcon />
            </Button>
          </InputRightElement>
        </InputGroup>

        {/* Assigned User Dropdown */}
        <div className="filter-container">
          <label htmlFor="assignedUser">Filter by Assigned User:</label>
          <select
            id="assignedUser"
            value={assignedUser}
            onChange={(e) => setAssignedUser(e.target.value)}
          >
            <option value="all">All</option>
            {assignedUsers.map((user) => (
              <option key={user} value={user}>
                {user}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="leads-container">
        {currentLeads.map((lead) => (
          <div className="lead-box" key={lead.id}>
            <div className="lead-header">
              {lead.name}
              <button className="dropdown-btn" onClick={() => toggleLeadDetails(lead.id)}>
                {expandedLeads[lead.id] ? '▲' : '▼'}
              </button>
            </div>
            {expandedLeads[lead.id] && (
              <>
                <div className="lead-details">
                  <p><strong>Email:</strong> {lead.email}</p>
                  <p><strong>Phone:</strong> {lead.phone}</p>
                  <p><strong>Description:</strong> {lead.description}</p>
                  {lead.comment ? (
                    <p><strong>Comment:</strong> {lead.comment}</p>
                  ) : (
                    <p><em>No comments yet</em></p>
                  )}
                  <p><strong>Created At:</strong> {new Date(lead.created_at).toLocaleString()}</p>
                </div>
                <div className={`assigned-badge ${lead.assigned ? 'assigned' : 'not-assigned'}`}>
                  {lead.assigned
                    ? `Assigned to ${lead.last_assigned_role === 'admin' ? 'Admin' : lead.assigned_username}`
                    : 'Not Assigned'}
                </div>
                <div className="lead-buttons">
                  <button className="comment-btn" onClick={() => handleCommentClick(lead.id)}>Add Comment</button>
                  <button className="view-comments-btn" onClick={() => handleViewCommentsClick(lead.id)}>View All Comments</button>
                  <button className="assign-btn" onClick={() => handleAssignClick(lead.id)}>Assign User</button>
                  <button className="edit-btn" onClick={() => handleEditClick(lead)}>Edit Lead</button>
                  <button className="history-btn" onClick={() => handleViewHistoryClick(lead.id)}>View History</button>
                  <button className="delete-btn" disabled>Delete Lead</button>
                </div>
              </>
            )}
          </div>
        ))}
      </div>

      {/* Pagination Controls */}
      <div className="pagination">
        <button
          className="pagination-btn prev-btn"
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1}
        >
          &lt; Prev
        </button>

        {renderPagination()}

        <button
          className="pagination-btn next-btn"
          onClick={() => paginate(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next &gt;
        </button>
      </div>

      {/* Modals */}
      {isCommentModalOpen && (
        <CommentModal
          leadId={selectedLeadId}
          onClose={handleCommentModalClose}
          onCommentAdded={handleLeadsRefresh}
        />
      )}
      {isCommentsModalOpen && (
        <ViewCommentsModal
          leadId={selectedLeadId}
          onClose={handleCommentsModalClose}
        />
      )}
      {isAssignModalOpen && (
        <AssignUserModal
          leadId={selectedLeadId}
          onClose={handleAssignModalClose}
          onAssignUser={handleAssignUser}
        />
      )}
      {isHistoryModalOpen && (
        <ViewHistoryModal
          leadId={selectedLeadId}
          onClose={handleHistoryModalClose}
        />
      )}
      {isEditModalOpen && (
        <EditLeadModal
          leadId={selectedLeadId}
          onClose={handleEditModalClose}
          onUpdate={handleLeadsRefresh}
          onSave={handleEditModalSave}
        />
      )}
    </div>
  );
};

export default AdminDashboard;
