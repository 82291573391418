import React, { useState } from 'react';
import axios from 'axios'; // Axios for making API requests
import './ManageUsersPage.css';

const ManageUsersPage = ({role}) => {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    role: '',
    password: '',
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState(''); // To show success or error message

  // Handle form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      // Send form data to backend API
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/users`, formData);

      if (response.status === 200) {
        setMessage('User added successfully!');
        setTimeout(() => setMessage(''), 3000); // Clear message after 3 seconds

        // Clear form data after submission
        setFormData({
          username: '',
          email: '',
          role: '',
          password: '',
        });
      }
    } catch (error) {
      setMessage('Failed to add user, please try again.');
      console.error('Error adding user:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  // Handle form reset on Cancel
  const handleCancel = () => {
    setFormData({
      username: '',
      email: '',
      role: '',
      password: '',
    });
    setMessage(''); // Clear message
  };

  return (
    <div className="manage-users-page">
      {/* Success or Error Message */}
      {message && <div className="message">{message}</div>}

      <div className="form-container">
        <h2 className="form-title">Add User</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="username">Username</label>
            <input
              type="text"
              id="username"
              name="username"
              placeholder="Enter username"
              value={formData.username}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Enter email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="role">Role</label>
            <select
              id="role"
              name="role"
              value={formData.role}
              onChange={handleChange}
              required
            >
              <option value="" disabled>
                Select role
              </option>
              {role === 'superuser' && (
                <option value="admin">Admin</option>
              )}
              <option value="support">Support</option>
              <option value="user">User</option>
              
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              name="password"
              placeholder="Enter password"
              value={formData.password}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-buttons">
            <button type="button" className="cancel-button" onClick={handleCancel}>
              Cancel
            </button>
            <button type="submit" className="submit-button" disabled={isSubmitting}>
              {isSubmitting ? 'Submitting...' : 'Submit'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ManageUsersPage;
