import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './ViewCommentsModal.css'; // Import CSS for styling

const ViewCommentsModal = ({ leadId, onClose }) => {
  const [comments, setComments] = useState([]);

  // Fetch comments for the lead when modal is opened
  useEffect(() => {
    const fetchComments = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/leads/${leadId}/comments`);
        setComments(response.data);
      } catch (error) {
        console.error('Error fetching comments:', error);
      }
    };

    fetchComments();
  }, [leadId]);

  return (
    <div className="modal-backdrop">
      <div className="modal-content">
        <h2>All Comments</h2>
        <div className="comments-container">
          {comments.length > 0 ? (
            comments.map((comment, index) => (
              <div key={index} className="comment-box">
                <p>{comment.comment}</p> {/* Show only the comment text */}
                <p><strong>Date:</strong> {new Date(comment.created_at).toLocaleString()}</p>
              </div>
            ))
          ) : (
            <p>No comments available for this lead.</p>
          )}
        </div>
        <button onClick={onClose} className="close-btn">Close</button>
      </div>
    </div>
  );
};

export default ViewCommentsModal;
