import React from 'react';

const SupportDashboard = () => {

  return (
    <div>
      <div className="dashboard-content">
        <h2>Welcome to the Support Dashboard</h2>
        {/* Support-specific content goes here */}
      </div>
    </div>
  );
};

export default SupportDashboard;
