// src/FrontEnd/Login/LoginOptions.js
import React from 'react';
import './LoginOptions.css';
import logo from '../../assets/FinalRedBG.svg';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation

const LoginOptions = () => {
  const navigate = useNavigate(); // Initialize useNavigate for programmatic navigation

  const handleLoginClick = (role) => {
    console.log(`Logging in as ${role}`);
    navigate(`/login/${role.toLowerCase()}`); // Navigate to login page with role as a URL parameter
  };

  return (
    <div className="login-container">
      <header className="header">
        <div className="logo-title">
          <img src={logo} alt="Mentor Leads Logo" className="logo" />
          <h1 className="title">Mentor Leads</h1>
        </div>
      </header>
      <div className="login-box">
        <h2>Login</h2>
        <p>Please choose your login type</p>
        <div className="button-group">
          <button className="login-button" onClick={() => handleLoginClick('Admin')}>
            Login as Admin
          </button>
          <button className="login-button" onClick={() => handleLoginClick('User')}>
            Login as User
          </button>
          <button className="login-button" onClick={() => handleLoginClick('Support')}>
            Login as Support
          </button>
          <button className="login-button" onClick={() => handleLoginClick('SuperUser')}>
            Login as Super User
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoginOptions;