// // NotificationContext.js
// import React, { createContext, useContext, useState } from 'react';

// // Create Notification Context
// export const NotificationContext = createContext();

// // Provider Component
// export const NotificationProvider = ({ children }) => {
//     const [notifications, setNotifications] = useState([]);
//     const seenNotificationIds = new Set(); // Track IDs to avoid duplicates

//     const addNotification = (notification) => {
//         if (!notification || !notification.id) return;

//         // Check if this notification ID is already seen
//         if (seenNotificationIds.has(notification.id)) {
//             console.log('Duplicate notification detected:', notification);
//             return; // Ignore duplicate notifications
//         }

//         // Add notification to the list and mark ID as seen
//         seenNotificationIds.add(notification.id);
//         setNotifications((prev) => {
//             console.log('Adding new notification to state:', notification); // Debugging log
//             return [notification, ...prev];
//         });
//     };

//     const clearNotifications = () => {
//         setNotifications([]);
//         seenNotificationIds.clear();
//     };

//     return (
//         <NotificationContext.Provider value={{ notifications, addNotification, clearNotifications }}>
//             {children}
//         </NotificationContext.Provider>
//     );
// };

// export const useNotificationContext = () => {
//     const context = useContext(NotificationContext);
//     if (!context) {
//         throw new Error("useNotificationContext must be used within a NotificationProvider");
//     }
//     return context;
// };

// NotificationContext.js
// NotificationContext.js
import React, { createContext, useContext, useState } from 'react';

export const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
    const [notifications, setNotifications] = useState([]);
    const seenNotificationIds = new Set(); // Track IDs to avoid duplicates

    const currentUserId = localStorage.getItem('user_id'); // Get current user ID

    const addNotification = (notification) => {
        // if (!notification || !notification.id || notification.userId !== parseInt(currentUserId)) {
        //     return; // Ignore notifications not meant for this user
        // }

        // Check if this notification ID is already seen
        if (seenNotificationIds.has(notification.id)) {
            console.log('Duplicate notification detected:', notification);
            return;
        }

        // Add notification to the list and mark ID as seen
        seenNotificationIds.add(notification.id);
        setNotifications((prev) => {
            console.log('Adding new notification to state:', notification); // Debugging log
            return [notification, ...prev];
        });
    };

    return (
        <NotificationContext.Provider value={{ notifications, addNotification }}>
            {children}
        </NotificationContext.Provider>
    );
};

export const useNotificationContext = () => {
    const context = useContext(NotificationContext);
    if (!context) {
        throw new Error("useNotificationContext must be used within a NotificationProvider");
    }
    return context;
};
