import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './EditLeadsModal.css'; // Optional: Add custom styling for the modal

const EditLeadModal = ({ leadId, onClose, onUpdate }) => {
    
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    description: '',
  });

  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    console.log(leadId);
    // Fetch the current lead data
    if (leadId) {
      const fetchLead = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/leads/${leadId}`);
          setFormData(response.data); // Set the lead data to form state
        } catch (error) {
          setErrorMessage('Error fetching lead data.');
        }
      };
  
      fetchLead();
    }
  }, [leadId]); // Only run if leadId is available
  
  // Handle form submission to update lead
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Log the form data for debugging
    console.log('Submitting updated lead:', formData);
  
    try {
      await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/leads/${leadId}`, formData);
      onUpdate(); // Call the update function to refresh the leads list
      // Reset the form fields
    setFormData({
        name: '',
        email: '',
        phone: '',
        description: '',
      });
      setTimeout(() => {
        onClose();
      }, 100); // Close the modal after successful update
    } catch (error) {
      console.error('Error updating lead:', error);
      setErrorMessage('Error updating lead. Please try again.');
    }
  };  


  // Handle form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

//   // Handle form submission to update lead
//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     try {
//       await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/leads/${leadId}`, formData);
//       onUpdate(); // Call the update function to refresh the leads list
//       onClose(); // Close the modal after successful update
//     } catch (error) {
//       setErrorMessage('Error updating lead. Please try again.');
//     }
//   };

  return (
    <div className="modal-backdrop">
      <div className="modal-content">
        <h2>Edit Lead</h2>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="phone">Phone</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="description">Description</label>
            <textarea
              id="description"
              name="description"
              value={formData.description}
              onChange={handleChange}
              required
            ></textarea>
          </div>
          <div className="modal-actions">
            <button type="submit" className="save-btn">Save Changes</button>
            <button type="button" onClick={onClose} className="cancel-btn">Cancel</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditLeadModal;
