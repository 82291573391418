import React from 'react';
import { useNotificationContext } from './NotificationContext';
import './Notification.css';

function NotificationPage() {
    const { notifications } = useNotificationContext();
    console.log('Notifications to display:', notifications);

    return (
        <div className="notification-page">
            <h2 className="notification-title">Notifications</h2>
            {notifications.length > 0 ? (
                <div className="notification-list">
                    {notifications.map((notification, index) => (
                        <div key={index} className="notification-item">
                            <div className="notification-icon">🔔</div>
                            <div className="notification-content">
                                <p className="notification-message">{notification.message}</p>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <p className="no-notifications">No new notifications.</p>
            )}
        </div>
    );
}

export default NotificationPage;
