import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Import Axios for making API requests
import './AddLeadsPage.css'; // Optional: Custom CSS for styling
import { saveAs } from 'file-saver'; // For file downloading
import Papa from 'papaparse';

const AddLeadsPage = ({ role }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    description: '',
  });

  const [isSubmitting, setIsSubmitting] = useState(false); // State to handle form submission status
  const [formSuccessMessage, setFormSuccessMessage] = useState(''); // State to handle success message for form
  const [formErrorMessage, setFormErrorMessage] = useState(''); // State to handle error message for form
  const [adminId, setAdminId] = useState(null); // To store the admin ID

  const [isCsvModalOpen, setIsCsvModalOpen] = useState(false); // State to handle the CSV modal visibility
  const [csvFile, setCsvFile] = useState(null); // State for the uploaded CSV file
  const [isCsvPopUpVisible, setIsCsvPopUpVisible] = useState(false); // State for showing CSV pop-up
  const [csvPopUpMessage, setCsvPopUpMessage] = useState(''); // State for CSV pop-up message
  const [isUploading, setIsUploading] = useState(false); // State to show upload progress

  // Fetch the admin ID when the component loads (only for support role)
  useEffect(() => {
    if (role === 'support') {
      // Fetch the first admin user from the database to assign leads
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/api/users/admin`)
        .then((response) => {
          if (response.data && response.data.id) {
            setAdminId(response.data.id); // Set the adminId for later use
          }
        })
        .catch((error) => {
          console.error('Error fetching admin ID:', error);
        });
    }
  }, [role]);

  // Handle form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle form submission for adding a lead
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const userId = localStorage.getItem('user_id');
      const requestBody = { ...formData, role, supportUserId: userId, assignedUserId: adminId }; // Include assignedUserId as adminId for support
      console.log('Submitting form with data:', requestBody); // Log for debugging

      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/leads`, requestBody);

      if (response.status === 200) {
        setFormSuccessMessage('Lead added successfully!');
        setFormErrorMessage('');
        setFormData({
          name: '',
          email: '',
          phone: '',
          description: '',
        });
        setTimeout(() => {
          setFormSuccessMessage('');
        }, 3000);
      }
    } catch (error) {
      console.error('Error submitting form:', error.response?.data || error.message);
      setFormErrorMessage('Failed to submit the lead. Please try again.');
      setTimeout(() => {
        setFormErrorMessage('');
      }, 3000);
    } finally {
      setIsSubmitting(false);
    }
  };

  // Handle form reset on Cancel
  const handleCancel = () => {
    setFormData({
      name: '',
      email: '',
      phone: '',
      description: '',
    });
  };

  // Handle CSV import button click (open modal)
  const handleImportCSV = () => {
    setIsCsvModalOpen(true); // Open the CSV modal
  };

  // Handle CSV file change
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setCsvFile(file);
  };

  // Handle CSV file submission
 // Handle CSV file submission
const handleCSVSubmit = async (e) => {
  e.preventDefault();

  if (!csvFile) {
    showCsvPopUp('Please select a CSV file.');
    return;
  }

  setIsUploading(true); // Disable upload button and show uploading message

  // Get user ID from localStorage
  const userId = localStorage.getItem('user_id'); // Get the user ID from local storage

  let assignedUserId = null; // Default to no assignment

  // Determine assignment logic based on role
  if (role === 'support') {
    assignedUserId = adminId; // Assign to admin if role is 'support'
  } else if (role === 'user') {
    assignedUserId = userId; // Assign to the user themselves if role is 'user'
  }

  // Use FileReader to read the CSV file and count rows
  const reader = new FileReader();
  reader.onload = async (event) => {
    const csvContent = event.target.result;
    const rowCount = csvContent.split('\n').length; // Count the rows in the CSV file

    // If more than 10 rows, show the message
    if (rowCount > 10) {
      showCsvPopUp('File uploading, please wait...');
    }

    // Proceed with form submission after file processing
    const formData = new FormData();
    formData.append('file', csvFile);
    formData.append('role', role);  // Include the user's role (support, user, or admin)
    formData.append('assignedUserId', assignedUserId);  // Set to adminId, userId, or null based on the role

    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/leads/import-csv`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        showCsvPopUp(response.data.message);
        setIsCsvModalOpen(false); // Close modal after successful upload
        setCsvFile(null); // Reset the selected CSV file
      }
    } catch (error) {
      console.error('Error importing CSV:', error);
      showCsvPopUp('Error importing CSV: ' + (error.response?.data?.message || 'Unknown error'));
    } finally {
      setIsUploading(false); // Enable the upload button after completion
    }
  };

  reader.readAsText(csvFile); // Read the CSV file
};


  // Handle closing the CSV modal
  const handleCloseModal = () => {
    setIsCsvModalOpen(false);
    setCsvFile(null); // Clear the selected file
  };

  // Show CSV pop-up for messages
  const showCsvPopUp = (message) => {
    setCsvPopUpMessage(message);
    setIsCsvPopUpVisible(true);
    setTimeout(() => {
      setIsCsvPopUpVisible(false); // Hide pop-up after 3 seconds
    }, 7000);
  };

  // Export CSV functionality
  const handleExportCSV = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/leads/export-csv`, {
        responseType: 'blob', // Ensure the response is treated as a blob
      });
  
      console.log('CSV Response:', response.data); // Log the data to verify it is received
  
      const blob = new Blob([response.data], { type: 'text/csv;charset=utf-8;' });
      saveAs(blob, 'leads_export.csv'); // Download the file
    } catch (error) {
      console.error('Error exporting CSV:', error);
    }
  };

  return (
    <div className="add-leads-page">
      {/* CSV Import Button */}
      <div className="import-button-container">
        <button className="import-button" onClick={handleImportCSV}>
          Import Leads
        </button>
        {role === 'superuser' && (
          <button className="export-button" onClick={handleExportCSV}>
            Export Leads
          </button>
        )}
      </div>

      {/* CSV Upload Modal */}
      {isCsvModalOpen && (
        <div className="modal-backdrop">
          <div className="modal-content">
            <h2>Import CSV</h2>
            <form onSubmit={handleCSVSubmit}>
              <input type="file" accept=".csv" onChange={handleFileChange} />
              <div className="modal-actions">
                <button type="submit" className={isUploading ? 'uploading' : ''} disabled={isUploading}>
                  {isUploading ? 'Uploading...' : 'Upload'}
                </button>
                <button type="button" onClick={handleCloseModal} disabled={isUploading}>
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Form container */}
      <div className="form-container">
        <h2 className="form-title">Add Leads</h2>

        {/* Success message for form */}
        {formSuccessMessage && <p className="success-message">{formSuccessMessage}</p>}

        {/* Error message for form */}
        {formErrorMessage && <p className="error-message">{formErrorMessage}</p>}

        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Enter name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email Address</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Enter email address"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="phone">Phone No.</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              placeholder="Enter phone number"
              value={formData.phone}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="description">Description</label>
            <textarea
              id="description"
              name="description"
              placeholder="Enter description"
              value={formData.description}
              onChange={handleChange}
              required
            ></textarea>
          </div>
          <div className="form-buttons">
            <button type="button" className="cancel-button" onClick={handleCancel}>
              Cancel
            </button>
            <button type="submit" className="submit-button" disabled={isSubmitting}>
              {isSubmitting ? 'Submitting...' : 'Submit'}
            </button>
          </div>
        </form>
      </div>

      {/* Pop-up window for CSV import messages */}
      {isCsvPopUpVisible && (
        <div className="pop-up">
          <p>{csvPopUpMessage}</p>
        </div>
      )}
    </div>
  );
};

export default AddLeadsPage;
