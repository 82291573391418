import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom'; // Import BrowserRouter
import App from './App';
import { ChakraProvider } from '@chakra-ui/react';
import { NotificationProvider } from './FrontEnd/Notification/NotificationContext';

ReactDOM.render(
  <NotificationProvider>
    <ChakraProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ChakraProvider>
  </NotificationProvider>,
  document.getElementById('root')
);
