import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './AssignUserModal.css';

const AssignUserModal = ({ leadId, onClose, onAssignUser }) => {
  const [users, setUsers] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [assignedUserId, setAssignedUserId] = useState(null);
  const [assignedRole, setAssignedRole] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedType, setSelectedType] = useState(null); // Track if selection is from user or admin
  const [error, setError] = useState(null);
  const [assigning, setAssigning] = useState(false);

  const loggedInUserRole = localStorage.getItem('role');  // Get the role from localStorage

  useEffect(() => {
    const fetchUsersAndAdmins = async () => {
      try {
        if (loggedInUserRole === 'admin') {
          const usersResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/users`);
          setUsers(usersResponse.data);
        } else if (loggedInUserRole === 'user') {
          const adminsResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/admins`);
          setAdmins(adminsResponse.data);
        } else if (loggedInUserRole === 'superuser') {
          const usersResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/users`);
          const adminsResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/admins`);
          setUsers(usersResponse.data);
          setAdmins(adminsResponse.data);
        }

        // Fetch the current assigned user or admin
        const assignedResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/leads/${leadId}/assignedUser`);
        setAssignedUserId(assignedResponse.data.assignedUserId || null);
        setAssignedRole(assignedResponse.data.role || null);
      } catch (error) {
        console.error('Error fetching users/admins or assigned user:', error);
      }
    };

    if (loggedInUserRole) {
      fetchUsersAndAdmins();
    }
  }, [leadId, loggedInUserRole]);

  const handleAssign = async () => {
    if (!selectedId || !selectedType) {
      setError('Please select a user or admin to assign.');
      return;
    }
    const assignerId = localStorage.getItem('user_id');
    const role = selectedType; // Explicitly set role based on whether the selection is from users or admins

    try {
      if (!assigning) {
        setAssigning(true);  // Prevent multiple assignments

        await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/leads/${leadId}/assign`, {
          userId: selectedId,
          assignerId,
          role,  // This role is now 'admin' or 'user' based on the selected type
          assignerRole: loggedInUserRole
        });

        onAssignUser(selectedId);
        setAssigning(false);
        onClose();
      }
    } catch (error) {
      console.error('Error assigning user/admin:', error);
      setError('Failed to assign. Please try again.');
      setAssigning(false);
    }
  };

  const handleUnassign = async () => {
    if (!assignedUserId) {
      alert('No user assigned to unassign.');
      return;
    }

    const assignerId = localStorage.getItem('user_id');
    const assignerRole = localStorage.getItem('role');

    try {
      const response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/leads/${leadId}/unassign`, {
        userId: assignedUserId,
        assignerId,
        assignerRole
      });
      alert(response.data.message);
      setAssignedUserId(null);
      setAssignedRole(null);
      onAssignUser();
      onClose();
    } catch (error) {
      console.error('Error unassigning lead:', error);
      alert('Failed to unassign lead. Please try again.');
    }
  };

  return (
    <div className="modal-backdrop">
      <div className="modal-content">
        <h2>
          {loggedInUserRole === 'admin' ? 'Assign Lead to User' :
           loggedInUserRole === 'user' ? 'Assign Lead to Admin' : 
           'Assign Lead to User or Admin'}
        </h2>
        {error && <p className="error-message">{error}</p>}

        {/* Show users list if logged-in role is admin or superuser */}
        {(loggedInUserRole === 'admin' || loggedInUserRole === 'superuser') && (
          <>
            <h3>Users</h3>
            <ul className="user-list">
              {users.map((user) => (
                <li key={user.id} className="user-item">
                  <label>
                    <input
                      type="radio"
                      name="selectedUser"
                      value={user.id}
                      checked={selectedId === user.id && selectedType === 'user'}
                      onChange={() => {
                        setSelectedId(user.id);
                        setSelectedType('user');  // Explicitly set the type as 'user'
                      }}
                      className="circle-checkbox"
                    />
                    <span className="checkbox-label">{user.username}</span>
                    {assignedUserId === user.id && assignedRole === 'user' && (
                      <span className="assigned-tick">✔️</span>
                    )}
                  </label>
                </li>
              ))}
            </ul>
          </>
        )}

        {/* Show admins list if logged-in role is user or superuser */}
        {(loggedInUserRole === 'user' || loggedInUserRole === 'superuser') && (
          <>
            <h3>Admins</h3>
            <ul className="user-list">
              {admins.map((admin) => (
                <li key={admin.id} className="user-item">
                  <label>
                    <input
                      type="radio"
                      name="selectedAdmin"
                      value={admin.id}
                      checked={selectedId === admin.id && selectedType === 'admin'}
                      onChange={() => {
                        setSelectedId(admin.id);
                        setSelectedType('admin');  // Explicitly set the type as 'admin'
                      }}
                      className="circle-checkbox"
                    />
                    <span className="checkbox-label">{admin.username}</span>
                    {assignedUserId === admin.id && assignedRole === 'admin' && (
                      <span className="assigned-tick">✔️</span>
                    )}
                  </label>
                </li>
              ))}
            </ul>
          </>
        )}

        <div className="modal-actions">
          <button onClick={handleAssign} className="assign-btn" disabled={!selectedId || assigning}>
            {assigning ? 'Assigning...' : 'Assign'}
          </button>
          <button onClick={handleUnassign} className="unassign-btn" disabled={!assignedUserId}>
            Unassign
          </button>
          <button onClick={onClose}>Close</button>
        </div>
      </div>
    </div>
  );
};

export default AssignUserModal;
