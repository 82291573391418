import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ManageUsers.css';
import ViewLeadsModal from '../ViewLeadsModal/ViewLeadsModal';
import ChangePasswordModal from '../ChangePasswordModal/ChangePasswordModal';

const ManageUsers = ({ role }) => {
  const [users, setUsers] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] = useState(false);
  const [selectedUserForPasswordChange, setSelectedUserForPasswordChange] = useState(null);

  // Fetch users with their assigned leads from the backend
  const fetchUsersWithLeads = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/users-with-leads`);
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  useEffect(() => {
    fetchUsersWithLeads();
  }, []);

  const handleDeleteUser = async (userId) => {
    try {
      // Check if user has any assigned leads
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/users/${userId}/leads`);
      const assignedLeads = response.data;
  
      if (assignedLeads.length > 0) {
        setErrorMessage('User has assigned leads. Please delete leads before deleting the user.');
        setTimeout(() => setErrorMessage(''), 3000);
      } else {
        // Proceed to delete the user if no leads are assigned
        const deleteResponse = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/users/${userId}`);
        alert(deleteResponse.data.message);
        fetchUsersWithLeads();  // Refresh the user list after deletion
      }
    } catch (error) {
      console.error('Error deleting user:', error);
      setErrorMessage('Failed to delete user. Please try again.');
      setTimeout(() => setErrorMessage(''), 3000);
    }
  };
  

  const handleDeleteLeads = async (userId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/users/${userId}/leads`);
      const assignedLeads = response.data;
  
      if (assignedLeads.length > 0) {
        // Attempt to delete each lead assignment for the user
        const deleteResults = await Promise.all(
          assignedLeads.map(async (lead) => {
            const leadId = lead.lead_id || lead.id; // Ensure correct field name
  
            try {
              // Only attempts to delete if role is 'user'
              const deleteResponse = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/lead-assignments/${userId}/${leadId}`);
              console.log(`Successfully deleted lead assignment for userId: ${userId}, leadId: ${leadId}`);
              return deleteResponse.data.message;
            } catch (error) {
              console.error(`Failed to delete lead assignment for userId: ${userId}, leadId: ${leadId}`, error.response?.data || error.message);
              throw error; // Ensures any failed deletion stops the process
            }
          })
        );
        alert('All assigned leads deleted successfully for the user.');
        fetchUsersWithLeads(); // Refresh the user list to reflect changes
      } else {
        setErrorMessage('No leads assigned to this user.');
        setTimeout(() => setErrorMessage(''), 3000);
      }
    } catch (error) {
      console.error('Error deleting leads:', error.response || error.message);
      setErrorMessage('Failed to delete leads. Please try again.');
      setTimeout(() => setErrorMessage(''), 3000);
    }
  };
  
  

  const handleChangePassword = (userId) => {
    alert(`Change password for user with ID: ${userId}`);
    // Add your password change logic here
  };

  const openModal = (userId) => {
    setSelectedUserId(userId);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openChangePasswordModal = (userId) => {
    setSelectedUserForPasswordChange(userId);
    setIsChangePasswordModalOpen(true);
  };

  const closeChangePasswordModal = () => {
    setIsChangePasswordModalOpen(false);
  };

  return (
    <div className="manage-users-page">
      <h2>Manage Users</h2>
      {errorMessage && <p className="error-message">{errorMessage}</p>}

      {/* Regular Table for Large Screens */}
      <div className="table-container">
        <table className="users-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Assigned Leads</th>
              {role === 'superuser' && <th>Manage Password</th>}
              <th>Delete User</th>
              <th>Delete Leads</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user.id}>
                <td>{user.username}</td>
                <td>{user.email}</td>
                <td>
                  {user.assigned_leads && user.assigned_leads.length > 0 ? (
                    <button
                      className="view-leads-button"
                      onClick={() => openModal(user.id)}
                    >
                      View Leads
                    </button>
                  ) : 'No leads assigned'}
                </td>
                {role === 'superuser' && (
                  <td>
                    <button
                      className="change-password-button"
                      onClick={() => openChangePasswordModal(user.id)}
                    >
                      Change Password
                    </button>
                  </td>
                )}
                <td>
                  <button
                    className="delete-button"
                    disabled
                    // onClick={() => handleDeleteUser(user.id)}
                  >
                    Delete User
                  </button>
                </td>
                <td>
                  {user.assigned_leads && user.assigned_leads.length > 0 ? (
                    <button
                      className="delete-button"
                      disabled
                      // onClick={() => handleDeleteLeads(user.id)}
                    >
                      Delete Leads
                    </button>
                  ) : (
                    'No leads assigned'
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Card Layout for Small Screens */}
      <div className="user-cards">
        {users.map((user) => (
          <div className="user-card" key={user.id}>
            <h3>{user.username}</h3>
            <p><strong>Email:</strong> {user.email}</p>
            <p><strong>Assigned Leads:</strong>
              {user.assigned_leads && user.assigned_leads.length > 0 ? (
                <button
                  className="view-leads-button"
                  onClick={() => openModal(user.id)}
                >
                  View Leads
                </button>
              ) : 'No leads assigned'}
            </p>

            <div className="delete-buttons">
              {role === 'superuser' && (
                <button
                  className="change-password-button"
                  onClick={() => openChangePasswordModal(user.id)}
                >
                  Change Password
                </button>
              )}
              <button
                className="delete-button"
                disabled
                // onClick={() => handleDeleteUser(user.id)}
              >
                Delete User
              </button>
              {user.assigned_leads && user.assigned_leads.length > 0 ? (
                <button
                  className="delete-button"
                  disabled
                  // onClick={() => handleDeleteLeads(user.id)}
                >
                  Delete Leads
                </button>
              ) : (
                <span>No leads assigned</span>
              )}
            </div>
          </div>
        ))}
      </div>

      {/* Render View Leads Modal */}
      {isModalOpen && (
        <ViewLeadsModal
          userId={selectedUserId}
          onClose={closeModal}
        />
      )}

      {isChangePasswordModalOpen && (
        <ChangePasswordModal
          userId={selectedUserForPasswordChange}
          onClose={closeChangePasswordModal}
          onPasswordChange={fetchUsersWithLeads} // Refresh user list if needed
        />
      )}
    </div>
  );
};

export default ManageUsers;
