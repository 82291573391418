import React, { useEffect, useState } from 'react';
import axios from 'axios';
import CommentModal from '../CommentModal/CommentModal';
import AssignUserModal from '../AssignUserModal/AssignUserModal';
import ViewHistoryModal from '../ViewHistoryModal/ViewHistoryModal';
import ViewCommentsModal from '../ViewCommentsModal/ViewCommentsModal';
import './SuperUserDashboard.css';
import { InputGroup, Input, InputRightElement, Button } from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import EditLeadModal from '../EditLeadsModal/EditLeadsModal';

const SuperUserDashboard = () => {
  const [leads, setLeads] = useState([]);
  const [filteredLeads, setFilteredLeads] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const leadsPerPage = 15;
  const [selectedLeadId, setSelectedLeadId] = useState(null);
  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
  const [isCommentsModalOpen, setIsCommentsModalOpen] = useState(false);
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
  const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [expandedLeadId, setExpandedLeadId] = useState(null); // Track expanded/collapsed state
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedLeadData, setSelectedLeadData] = useState(null);

  useEffect(() => {
    fetchLeads();
  }, []);

  const fetchLeads = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/leads`);
      setLeads(response.data);
      setFilteredLeads(response.data);
    } catch (error) {
      console.error('Error fetching leads:', error);
    }
  };

  const handleEditClick = (lead) => {
    
    console.log("Lead object: ", lead);
    setSelectedLeadData(lead); // Store selected lead data
    setSelectedLeadId(lead.id); // Make sure you're storing the lead's id correctly
    console.log("Selected Lead ID: ", lead.id); // Log the selected lead ID for debugging
    setIsEditModalOpen(true);   // Open the edit modal
  };

  const handleEditModalClose = () => {
    setIsEditModalOpen(false); // Close the edit modal
  };

  const handleEditModalSave = () => {
    // Logic to save updated lead data
    fetchLeads(); // Refresh leads after editing
    setIsEditModalOpen(false); // Close the modal after saving
  };

  const handleSearch = () => {
    const query = searchQuery.toLowerCase();
    const filtered = leads.filter((lead) =>
      (lead.name && lead.name.toLowerCase().includes(query)) ||
      (lead.email && lead.email.toLowerCase().includes(query)) ||
      (lead.phone && lead.phone.toLowerCase().includes(query))
    );
    setFilteredLeads(filtered);
    setCurrentPage(1);
  };

  useEffect(() => {
    if (searchQuery === '') {
      setFilteredLeads(leads);
      setCurrentPage(1);
    } else {
      handleSearch();
    }
  }, [searchQuery, leads]);

  const handleDeleteClick = async (leadId) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this lead?');
    if (!confirmDelete) {
      return;
    }

    try {
      await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/deleteleads/${leadId}`);
      alert('Lead deleted successfully');
      fetchLeads();
    } catch (error) {
      console.error('Error deleting lead:', error);
      alert('Error deleting lead');
    }
  };

  const toggleLeadDetails = (leadId) => {
    if (expandedLeadId === leadId) {
      setExpandedLeadId(null); // Collapse if already expanded
    } else {
      setExpandedLeadId(leadId); // Expand the selected lead
    }
  };

  const indexOfLastLead = currentPage * leadsPerPage;
  const indexOfFirstLead = indexOfLastLead - leadsPerPage;
  const currentLeads = filteredLeads.slice(indexOfFirstLead, indexOfLastLead);
  const totalPages = Math.ceil(filteredLeads.length / leadsPerPage);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleCommentClick = (leadId) => {
    setSelectedLeadId(leadId);
    setIsCommentModalOpen(true);
  };

  const handleViewCommentsClick = (leadId) => {
    setSelectedLeadId(leadId);
    setIsCommentsModalOpen(true);
  };

  const handleAssignClick = (leadId) => {
    setSelectedLeadId(leadId);
    setIsAssignModalOpen(true);
  };

  const handleViewHistoryClick = (leadId) => {
    setSelectedLeadId(leadId);
    setIsHistoryModalOpen(true);
  };

  const handleCommentModalClose = () => {
    setIsCommentModalOpen(false);
  };

  const handleCommentsModalClose = () => {
    setIsCommentsModalOpen(false);
  };

  const handleAssignModalClose = () => {
    setIsAssignModalOpen(false);
  };

  const handleHistoryModalClose = () => {
    setIsHistoryModalOpen(false);
  };

  const handleLeadsRefresh = () => {
    fetchLeads();
  };

  const handleAssignUser = () => {
    handleLeadsRefresh();
  };

  const renderPagination = () => {
    const pageNumbers = [];
    const totalNumbers = 5;

    if (totalPages > totalNumbers + 2) {
      const startPage = Math.max(2, currentPage - 2);
      const endPage = Math.min(totalPages - 1, currentPage + 2);

      pageNumbers.push(1);

      if (startPage > 2) {
        pageNumbers.push('...');
      }

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }

      if (endPage < totalPages - 1) {
        pageNumbers.push('...');
      }

      pageNumbers.push(totalPages);
    } else {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    }

    return pageNumbers.map((number, index) => (
      <button
        key={index}
        className={`pagination-btn ${currentPage === number ? 'active' : ''}`}
        onClick={() => paginate(number)}
        disabled={number === '...'}
      >
        {number}
      </button>
    ));
  };

  return (
    <div className="dashboard-content">
      {/* Title and Search Bar */}
      <div className="header-container">
        <h2>Leads Insights</h2>
        <InputGroup size="lg" className="search-bar" style={{ marginLeft: '20px' }}>
          <Input
            pr="4.5rem"
            type="text"
            placeholder="Search leads..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyDown={(e) => e.key === 'Enter' && handleSearch()}
          />
          <InputRightElement width="4.5rem">
            <Button h="1.75rem" size="sm" onClick={handleSearch}>
              <SearchIcon />
            </Button>
          </InputRightElement>
        </InputGroup>
      </div>

      <div className="leads-container">
        {currentLeads.map((lead) => (
          <div className="lead-box" key={lead.id}>
            {/* Only Show Name and Dropdown Button Initially */}
            {/* <div className="lead-header">
              <span>{lead.name}</span>
              <Button
                onClick={() => toggleLeadDetails(lead.id)}
                style={{ marginLeft: '10px' }}
              >
                {expandedLeadId === lead.id ? '▲' : '▼'} 
          </Button>
            </div> */}
            <div className="lead-header">
              <h3>{lead.name}</h3>
              <button className="dropdown-btn" onClick={() => toggleLeadDetails(lead.id)}>
                {expandedLeadId === lead.id ? '▲' : '▼'}
              </button>
            </div>

            {/* Conditionally Show Full Lead Details */}
            {expandedLeadId === lead.id && (
              <div className="lead-details">
                <p><strong>Email:</strong> {lead.email}</p>
                <p><strong>Phone:</strong> {lead.phone}</p>
                <p><strong>Description:</strong> {lead.description}</p>
                {lead.comment ? (
                  <p><strong>Comment:</strong> {lead.comment}</p>
                ) : (
                  <p><strong>Comment:</strong> <em>No comments yet</em></p>
                )}
                <p><strong>Created At:</strong> {new Date(lead.created_at).toLocaleString()}</p>

                <div className={`assigned-badge ${lead.assigned ? 'assigned' : 'not-assigned'}`}>
                  {lead.assigned
                    ? `Assigned to ${lead.last_assigned_role === 'admin' ? 'Admin' : lead.assigned_username}`
                    : 'Not Assigned'}
                </div>

                <div className="lead-buttons">
                  <button className="comment-btn" onClick={() => handleCommentClick(lead.id)}>Add Comment</button>
                  <button className="view-comments-btn" onClick={() => handleViewCommentsClick(lead.id)}>View All Comments</button>
                  <button className="assign-btn" onClick={() => handleAssignClick(lead.id)}>Assign User</button>
                  <button className="history-btn" onClick={() => handleViewHistoryClick(lead.id)}>View History</button>
                  <button className="edit-btn" onClick={() => handleEditClick(lead)}>Edit Lead</button>
                  <button className="delete-btn" onClick={() => handleDeleteClick(lead.id)}>Delete Lead</button>
                </div>
              </div>
            )}
          </div>
        ))
        }
      </div >

      {/* Pagination Controls */}
      < div className="pagination" >
        <button
          className="pagination-btn prev-btn"
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1}
        >
          &lt; Prev
        </button>

        {renderPagination()}

        <button
          className="pagination-btn next-btn"
          onClick={() => paginate(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next &gt;
        </button>
      </div >

      {/* Render Comment Modal */}
      {
        isCommentModalOpen && (
          <CommentModal
            leadId={selectedLeadId}
            onClose={() => setIsCommentModalOpen(false)}
            onCommentAdded={handleLeadsRefresh}
          />
        )
      }

      {/* Render View Comments Modal */}
      {
        isCommentsModalOpen && (
          <ViewCommentsModal
            leadId={selectedLeadId}
            onClose={() => setIsCommentsModalOpen(false)}
          />
        )
      }

      {/* Render Assign User Modal */}
      {
        isAssignModalOpen && (
          <AssignUserModal
            leadId={selectedLeadId}
            onClose={() => setIsAssignModalOpen(false)}
            onAssignUser={handleAssignUser}
          />
        )
      }

      {/* Render View History Modal */}
      {
        isHistoryModalOpen && (
          <ViewHistoryModal
            leadId={selectedLeadId}
            onClose={() => setIsHistoryModalOpen(false)}
          />
        )
      }

      {/* Render Edit Lead Modal */}
      {isEditModalOpen && (
        <EditLeadModal
          leadId={selectedLeadId}  // Use leadId to be consistent
          onClose={handleEditModalClose} // Function to close modal
          onUpdate={handleLeadsRefresh}
          onSave={handleEditModalSave}   // Function to handle saving the updated lead data
        />
      )}

    </div >
  );
};

export default SuperUserDashboard;
