import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './AdminAssignedLeadsPage.css';

const AdminAssignedLeadsPage = () => {
  const [leads, setLeads] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  // Fetch the logged-in user's role and user_id from localStorage
  const loggedInUserRole = localStorage.getItem('role');
  const loggedInUserId = localStorage.getItem('user_id');

  console.log('Logged-in User ID:', loggedInUserId); // For debugging
  console.log('Logged-in User Role:', loggedInUserRole); // For debugging

  useEffect(() => {
    const fetchLeads = async () => {
      if (!loggedInUserId) {
        console.error('User ID is missing');
        setErrorMessage('User is not logged in properly.');
        return;
      }

      try {
        let response;

        if (loggedInUserRole === 'admin') {
          // Fetch leads assigned to the admin
          response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/leads/assigned/admin`);
        } else if (loggedInUserRole === 'user') {
          // Fetch leads assigned to the user, passing user_id as a query param
          console.log('Fetching leads for user with ID:', loggedInUserId);
          response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/leads/assigned/user`, {
            params: { user_id: loggedInUserId }, // Pass user_id as a query param
          });
        }

        setLeads(response.data); // Set the fetched leads
      } catch (error) {
        console.error('Error fetching leads:', error);
        setErrorMessage('No Assigned leads to show.');
      }
    };

    fetchLeads();
  }, [loggedInUserRole, loggedInUserId]);

  return (
    <div className="assigned-leads-page">
      <h2>Leads Assigned to {loggedInUserRole === 'admin' ? 'Admin' : 'User'}</h2>
      {errorMessage && <p className="error-message">{errorMessage}</p>}
      <table className="leads-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          {leads.map((lead) => (
            <tr key={lead.id}>
              <td>{lead.name}</td>
              <td>{lead.email}</td>
              <td>{lead.phone}</td>
              <td>{lead.description}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AdminAssignedLeadsPage;
