import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './ViewLeadsModal.css'; // Add your own styles here

const ViewLeadsModal = ({ userId, onClose }) => {
  const [leads, setLeads] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  // Fetch leads assigned to the user
  const fetchLeads = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/users/${userId}/leads`);
      setLeads(response.data);
    } catch (error) {
      setErrorMessage('Failed to load leads. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLeads();
  }, [userId]);

  return (
    <div className="modal-backdrop">
      <div className="modal-content">
        <h2>Assigned Leads</h2>
        {loading && <p>Loading...</p>}
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        <ul className="leads-list">
          {leads.map(lead => (
            <li key={lead.id}>{lead.name} - {lead.email}</li>
          ))}
          {leads.length === 0 && <p>No leads assigned to this user.</p>}
        </ul>
        <div className="modal-actions">
          <button onClick={onClose}>Close</button>
        </div>
      </div>
    </div>
  );
};

export default ViewLeadsModal;
