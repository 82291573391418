import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { InputGroup, Input, InputRightElement, Button } from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import CommentModal from '../CommentModal/CommentModal';
import AssignUserModal from '../AssignUserModal/AssignUserModal';
import ViewHistoryModal from '../ViewHistoryModal/ViewHistoryModal';
import ViewCommentsModal from '../ViewCommentsModal/ViewCommentsModal';
import './UserDashboard.css';
import EditLeadModal from '../EditLeadsModal/EditLeadsModal';

const UserDashboard = () => {
  const [leads, setLeads] = useState([]);
  const [filteredLeads, setFilteredLeads] = useState([]); // State for filtered leads based on search
  const [selectedLeadId, setSelectedLeadId] = useState(null);
  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
  const [isCommentsModalOpen, setIsCommentsModalOpen] = useState(false);
  const [isAssignAdminModalOpen, setIsAssignAdminModalOpen] = useState(false);
  const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedLeadData, setSelectedLeadData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const leadsPerPage = 15;
  const [searchQuery, setSearchQuery] = useState(''); // State for search input
  const [expandedLeads, setExpandedLeads] = useState({}); // State to track expanded leads

  useEffect(() => {
    fetchLeads(); // Fetch leads on component mount
  }, []);

  const fetchLeads = async () => {
    const userId = localStorage.getItem('user_id');

    if (!userId) {
      console.error('User ID not found in local storage');
      return;
    }

    console.log('Retrieved userId:', userId);

    try {
      console.log(userId);

      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/user/leads?userId=${userId}`);
      console.log('Leads Response:', response.data);
      setLeads(response.data);
      setFilteredLeads(response.data); // Initialize filtered leads
    } catch (error) {
      console.error('Error fetching leads:', error);
    }
  };

  const handleEditClick = (lead) => {

    console.log("Lead object: ", lead);
    setSelectedLeadData(lead); // Store selected lead data
    setSelectedLeadId(lead.id); // Make sure you're storing the lead's id correctly
    console.log("Selected Lead ID: ", lead.id); // Log the selected lead ID for debugging
    setIsEditModalOpen(true);   // Open the edit modal
  };

  const handleEditModalClose = () => {
    setIsEditModalOpen(false); // Close the edit modal
  };

  const handleEditModalSave = () => {
    // Logic to save updated lead data
    fetchLeads(); // Refresh leads after editing
    setIsEditModalOpen(false); // Close the modal after saving
  };

  // Function to handle search input changes
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchQuery(value);

    if (value === '') {
      setFilteredLeads(leads); // Reset to all leads if search input is empty
    } else {
      const filtered = leads.filter(lead => lead.name.toLowerCase().includes(value.toLowerCase()));
      setFilteredLeads(filtered); // Set filtered leads based on search input
    }
  };

  // Function to toggle the expanded/collapsed state of a lead
  const toggleLeadDetails = (leadId) => {
    setExpandedLeads((prevState) => ({
      ...prevState,
      [leadId]: !prevState[leadId],
    }));
  };

  // Handle opening comment modal
  const handleCommentClick = (leadId) => {
    setSelectedLeadId(leadId);
    setIsCommentModalOpen(true);
  };

  // Handle opening assign admin modal
  const handleAssignAdminClick = (leadId) => {
    setSelectedLeadId(leadId);
    setIsAssignAdminModalOpen(true);
  };

  // Handle opening view all comments modal
  const handleViewCommentsClick = (leadId) => {
    setSelectedLeadId(leadId);
    setIsCommentsModalOpen(true); // Open the modal to view all comments
  };

  // Handle opening view history modal
  const handleViewHistoryClick = (leadId) => {
    setSelectedLeadId(leadId);
    setIsHistoryModalOpen(true);
  };

  // Close modals
  const handleCommentModalClose = () => setIsCommentModalOpen(false);
  const handleCommentsModalClose = () => setIsCommentsModalOpen(false);
  const handleAssignAdminModalClose = () => setIsAssignAdminModalOpen(false);
  const handleHistoryModalClose = () => setIsHistoryModalOpen(false);

  // Refresh leads after a comment is added or a user is assigned/unassigned
  const handleLeadsRefresh = async () => {
    await fetchLeads(); // Refresh leads
  };

  // Pagination logic
  const indexOfLastLead = currentPage * leadsPerPage;
  const indexOfFirstLead = indexOfLastLead - leadsPerPage;
  const currentLeads = filteredLeads.slice(indexOfFirstLead, indexOfLastLead); // Use filtered leads for current display
  const totalPages = Math.ceil(filteredLeads.length / leadsPerPage);

  // Function to change page
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to top on page change
  };

  // Pagination rendering logic with ellipsis
  const renderPagination = () => {
    const pageNumbers = [];
    const totalNumbers = 5; // Number of pagination buttons to show around the current page
    const totalBlocks = totalNumbers + 2; // Including 'Prev' and 'Next' buttons

    if (totalPages > totalBlocks) {
      const startPage = Math.max(2, currentPage - 2);
      const endPage = Math.min(totalPages - 1, currentPage + 2);

      pageNumbers.push(1);

      if (startPage > 2) {
        pageNumbers.push('...');
      }

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }

      if (endPage < totalPages - 1) {
        pageNumbers.push('...');
      }

      pageNumbers.push(totalPages);
    } else {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    }

    return pageNumbers.map((number, index) => (
      <button
        key={index}
        className={`pagination-btn ${currentPage === number ? 'active' : ''}`}
        onClick={() => paginate(number)}
        disabled={number === '...'}
      >
        {number}
      </button>
    ));
  };

  return (
    <div className="dashboard-content">
      <div className="header-container">
        <h2>User Leads Insights</h2>
        <InputGroup size="md" className="search-bar">
          <Input
            pr="4.5rem"
            type="text"
            placeholder="Search leads..."
            value={searchQuery}
            onChange={handleSearchChange}
          />
          <InputRightElement width="4.5rem">
            <Button h="1.75rem" size="sm" onClick={() => handleSearchChange({ target: { value: searchQuery } })}>
              <SearchIcon />
            </Button>
          </InputRightElement>
        </InputGroup>
      </div>

      <div className="leads-container">
        {currentLeads.map((lead) => (
          <div className="lead-box" key={lead.id}>
            <div className="lead-header">
              {lead.name}
              <button className="dropdown-btn" onClick={() => toggleLeadDetails(lead.id)}>
                {expandedLeads[lead.id] ? '▲' : '▼'}
              </button>
            </div>

            {/* Conditionally render lead details based on whether the lead is expanded or not */}
            {expandedLeads[lead.id] && (
              <>
                <div className="lead-details">
                  <p><strong>Email:</strong> {lead.email}</p>
                  <p><strong>Phone:</strong> {lead.phone}</p>
                  <p><strong>Description:</strong> {lead.description}</p>
                  {lead.comment ? (
                    <p><strong>Comment:</strong> {lead.comment}</p>
                  ) : (
                    <p><em>No comments yet</em></p>
                  )}
                </div>
                <div className={`assigned-badge ${lead.assigned ? 'assigned' : 'not-assigned'}`}>
                  {lead.assigned ? `Assigned to ${lead.assigned_username}` : 'Not Assigned'}
                </div>
                <div className="lead-buttons">
                  <button className="comment-btn" onClick={() => handleCommentClick(lead.id)}>Add Comment</button>
                  <button className="view-comments-btn" onClick={() => handleViewCommentsClick(lead.id)}>View All Comments</button>
                  <button className="assign-btn" onClick={() => handleAssignAdminClick(lead.id)}>Assign Admin</button>
                  <button className="edit-btn" onClick={() => handleEditClick(lead)}>Edit Lead</button>
                  <button className="history-btn" onClick={() => handleViewHistoryClick(lead.id)}>View History</button>
                </div>
              </>
            )}
          </div>
        ))}
      </div>

      {/* Pagination Controls */}
      <div className="pagination">
        <button
          className="pagination-btn prev-btn"
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1}
        >
          &lt; Prev
        </button>

        {renderPagination()}

        <button
          className="pagination-btn next-btn"
          onClick={() => paginate(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next &gt;
        </button>
      </div>

      {/* Comment Modal */}
      {isCommentModalOpen && (
        <CommentModal
          leadId={selectedLeadId}
          onClose={handleCommentModalClose}
          onCommentAdded={handleLeadsRefresh} // Call refresh on comment added
        />
      )}

      {/* View All Comments Modal */}
      {isCommentsModalOpen && (
        <ViewCommentsModal
          leadId={selectedLeadId}
          onClose={handleCommentsModalClose}
        />
      )}

      {/* Assign Admin Modal */}
      {isAssignAdminModalOpen && (
        <AssignUserModal
          title="Assign Admin"
          users={[]} // Fetch users accordingly
          leadId={selectedLeadId}
          onAssignUser={handleLeadsRefresh} // Handle the assignment
          onClose={handleAssignAdminModalClose}
          role="admin"
        />
      )}

      {/* View History Modal */}
      {isHistoryModalOpen && (
        <ViewHistoryModal
          leadId={selectedLeadId}
          onClose={handleHistoryModalClose}
        />
      )}

      {/* Render Edit Lead Modal */}
      {isEditModalOpen && (
        <EditLeadModal
          leadId={selectedLeadId}  // Use leadId to be consistent
          onClose={handleEditModalClose} // Function to close modal
          onUpdate={handleLeadsRefresh}
          onSave={handleEditModalSave}   // Function to handle saving the updated lead data
        />
      )}

    </div>
  );
};

export default UserDashboard;
