import React, { useState, useEffect,useContext } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { io } from 'socket.io-client';
import LoginOptions from './FrontEnd/LoginOptions/LoginOptions';
import LoginPage from './FrontEnd/Login/LoginPage';
import AdminDashboard from './FrontEnd/AdminDashboard/AdminDashboard';
import UserDashboard from './FrontEnd/UserDashboard/UserDashboard';
import SuperUserDashboard from './FrontEnd/SuperUserDasboard/SuperUserDashboard';
import SupportDashboard from './FrontEnd/SupportDashboard/SupportDashboard';
import AddLeadsPage from './FrontEnd/AddLeadsPage/AddLeadsPage';
import Navbar from './FrontEnd/Navbar/Navbar'; // Import Navbar
import ManageUsersPage from './FrontEnd/ManageUsersPage/ManageUsersPage';
import ManageUsers from './FrontEnd/ManageUsers/ManageUsers';
import TaskPanel from './FrontEnd/TaskPanel/TaskPanel';
import { NotificationContext } from './FrontEnd/Notification/NotificationContext';
import NotificationPanel from './FrontEnd/Notification/NotificationPanel';
import AdminAssignedLeadsPage from './FrontEnd/AdminAssignedLeadsPage/AdminAssignedLeadsPage';
import NotificationPage from './FrontEnd/Notification/NotificationPage';
import NotificationListener from './FrontEnd/Notification/NotificationListener';

// const socket = io(process.env.REACT_APP_BACKEND_URL);
// const socket = io(process.env.REACT_APP_BACKEND_URL);
const socket = io(process.env.REACT_APP_BACKEND_URL, {
  transports: ["websocket", "polling"],
  withCredentials: true
});

function App() {
  const location = useLocation();
  // const navigate = useNavigate();
  
  // State to hold the role
  const [role, setRole] = useState(null); 
  const { notifications, addNotification } = useContext(NotificationContext);
  
  useEffect(() => {
    // Check if role is stored in localStorage, if yes, set it
    const storedRole = localStorage.getItem('role');
    if (storedRole) {
      setRole(storedRole);
    }
  }, []);
  
  // Hide Navbar on login and root page
  const hideNavbar = location.pathname === '/' || location.pathname.startsWith('/login');
  useEffect(() => {
    socket.on('connect', () => {
      console.log('Connected to the server');
      // Join room for notifications based on user ID or role
      const userId = localStorage.getItem('userId'); // Adjust this as per your logic
      if (userId) {
        socket.emit('joinRoom', `user_${userId}`);
      }
    });
    
    socket.on('disconnect', () => {
      console.log('Disconnected from the server');
    });
  }, []);
  
  return (
    <>
      {/* Conditionally render Navbar if not on login and root pages */}
      {!hideNavbar && <Navbar role={role} />} 
      
      <NotificationListener role={role}/>
      <Routes>
        <Route path="/" element={<LoginOptions />} />
        <Route path="/login/:role" element={<LoginPage setRole={setRole} />} />
        <Route path="/admin-dashboard" element={<AdminDashboard />} />
        <Route path="/superuser-dashboard" element={<SuperUserDashboard />} />
        <Route path="/support-dashboard" element={<SupportDashboard />} />
        <Route path="/user-dashboard" element={<UserDashboard />} />
        <Route path="/task-panel" element={<TaskPanel socket={socket} role={role} />} />
        <Route path="/add-leads" element={<AddLeadsPage role={role} />} />
        <Route path="/manage-users" element={<ManageUsersPage role={role}/>} />
        <Route path="/manage" element={<ManageUsers role={role} />} />
        <Route path="/notifications" element={<NotificationPage />} />
        
        <Route path="/admin-assigned-leads" element={<AdminAssignedLeadsPage />} />
      </Routes>
    </>
  );
}

export default App;
