import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './TaskPanel.css';
import moment from 'moment-timezone';
import { useNotificationContext } from '../Notification/NotificationContext';

const TaskPanel = ({ socket,role}) => {
    
    const [tasks, setTasks] = useState([]);
    const [users, setUsers] = useState([]);
    const [admins, setAdmins] = useState([]);
    const [taskTitle, setTaskTitle] = useState('');
    const [taskDescription, setTaskDescription] = useState('');
    const [taskDueDate, setTaskDueDate] = useState('');
    const [taskReminderTime, setTaskReminderTime] = useState('');
    const [assignedTo, setAssignedTo] = useState('');
    const [isAddingTask, setIsAddingTask] = useState(false);
    const [isEditingTask, setIsEditingTask] = useState(false);
    const [editTaskId, setEditTaskId] = useState(null);

    const [sortOption, setSortOption] = useState('');
    const [statusFilter, setStatusFilter] = useState('all');
    const [editFilter, setEditFilter] = useState(false);
    const { addNotification } = useNotificationContext();

    const convertToAustralianTime = (dateTime) => {
        return moment(dateTime).tz("Australia/Sydney").format("YYYY-MM-DD HH:mm:ss");
    };
    

    const userId = localStorage.getItem('user_id');

    const loadTasks = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/tasks/${userId}`, {
                params: { role } // Pass role as a query parameter
            });
            const filteredTasks = response.data.map(task => ({ ...task, showDetails: false }));
            setTasks(filteredTasks);
        } catch (error) {
            console.error('Error fetching tasks:', error);
        }
    };

    const loadUsers = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/users`);
            setUsers(response.data);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    const loadAdmins = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/admins`);
            setAdmins(response.data);
        } catch (error) {
            console.error('Error fetching admins:', error);
        }
    };

    useEffect(() => {
        loadTasks();
        if (role === 'admin') {
            loadUsers();
            loadAdmins();
        }
    }, [userId, role]);

    const convertToMySQLFormat = (dateTime) => {
        const date = new Date(dateTime);
        const yyyy = date.getFullYear();
        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const dd = String(date.getDate()).padStart(2, '0');
        const hh = String(date.getHours()).padStart(2, '0');
        const min = String(date.getMinutes()).padStart(2, '0');
        const ss = '00';
        return `${yyyy}-${mm}-${dd} ${hh}:${min}:${ss}`;
    };

    const convertToLocalDateTime = (dateTime) => {
        const date = new Date(dateTime);
        const yyyy = date.getFullYear();
        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const dd = String(date.getDate()).padStart(2, '0');
        const hh = String(date.getHours()).padStart(2, '0');
        const min = String(date.getMinutes()).padStart(2, '0');
        return `${yyyy}-${mm}-${dd}T${hh}:${min}`;
    };

    const addTask = async () => {
        if (!taskTitle.trim()) {
            alert("Task title is required.");
            return;
        }
    
        if (!taskDueDate) {
            alert("Due date is required.");
            return;
        }
    
        const dueDate = new Date(taskDueDate);
        const reminderDate = taskReminderTime ? new Date(taskReminderTime) : null;
        const currentDate = new Date();
    
        // Check if the due date is in the past
        if (dueDate < currentDate) {
            alert("Due date cannot be in the past.");
            return;
        }
    
        // Check if the reminder time is after the due date
        if (reminderDate && reminderDate > dueDate) {
            alert("Reminder time cannot be after the due date.");
            return;
        }
    
        // Proceed if all validations pass
        try {
            const formattedDueDate = convertToAustralianTime(taskDueDate);
    const formattedReminderTime = taskReminderTime ? convertToAustralianTime(taskReminderTime) : null;

            let assignedUserId;
            let isUserTask = false;
    
            // Determine if the task is for a user or admin
            if (assignedTo.startsWith("user-")) {
                assignedUserId = assignedTo.replace("user-", "");
                isUserTask = true;
            } else if (assignedTo.startsWith("admin-")) {
                assignedUserId = assignedTo.replace("admin-", "");
                isUserTask = false;
            } else {
                assignedUserId = userId;
                isUserTask = role === 'user';
            }
    
            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/tasks`, {
                userId: assignedUserId,
                title: taskTitle,
                description: taskDescription,
                dueDate: formattedDueDate,
                reminderTime: formattedReminderTime,
                assigned_by: role === 'admin' ? userId : null,
                isUserTask
            });
    
            setTaskTitle('');
            setTaskDescription('');
            setTaskDueDate('');
            setTaskReminderTime('');
            setAssignedTo('');
            setIsAddingTask(false);
            loadTasks();
        } catch (error) {
            console.error("Error adding task:", error);
            alert("An error occurred while adding the task. Please try again.");
        }
    };    

    const deleteTask = async (taskId) => {
        try {
            await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/tasks/${taskId}`);
            loadTasks();
        } catch (error) {
            console.error('Error deleting task:', error);
        }
    };

    const markTaskComplete = async (taskId) => {
        try {
            await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/tasks/${taskId}/complete`);
            loadTasks();
        } catch (error) {
            console.error('Error marking task as complete:', error);
        }
    };

    const toggleDetails = (taskId) => {
        setTasks(tasks.map(task => task.id === taskId ? { ...task, showDetails: !task.showDetails } : task));
    };

    const openEditModal = (task) => {
        setEditTaskId(task.id);
        setTaskTitle(task.title);
        setTaskDescription(task.description);
        setTaskDueDate(task.dueDate ? convertToAustralianTime(task.dueDate) : '');
        setTaskReminderTime(task.reminderTime ? convertToAustralianTime(task.reminderTime) : '');
        setIsEditingTask(true);
    };

    const closeEditModal = () => {
        setIsEditingTask(false);
        setEditTaskId(null);
        setTaskTitle('');
        setTaskDescription('');
        setTaskDueDate('');
        setTaskReminderTime('');
    };

    const saveEditedTask = async () => {
        if (editTaskId && taskTitle.trim()) {
            try {
                const formattedDueDate = taskDueDate ? convertToMySQLFormat(taskDueDate) : null;
                const formattedReminderTime = taskReminderTime ? convertToMySQLFormat(taskReminderTime) : null;

                await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/tasks/${editTaskId}`, {
                    title: taskTitle,
                    description: taskDescription,
                    dueDate: formattedDueDate,
                    reminderTime: formattedReminderTime,
                    isEdited: true
                });
                closeEditModal();
                loadTasks();
            } catch (error) {
                console.error("Error updating task:", error);
            }
        }
    };

    const isDueDatePassed = (dueDate) => {
        return new Date(dueDate) < new Date();
    };

    const isDueToday = (dueDate) => {
        const today = new Date();
        const taskDate = new Date(dueDate);
        return (
            taskDate.getFullYear() === today.getFullYear() &&
            taskDate.getMonth() === today.getMonth() &&
            taskDate.getDate() === today.getDate()
        );
    };

    const getSortedTasks = (tasks) => {
        switch (sortOption) {
            case 'alphabetical':
                return [...tasks].sort((a, b) => a.title.localeCompare(b.title));
            case 'dueDate':
                return [...tasks].sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate));
            default:
                return tasks;
        }
    };
    const notifyUser = (taskTitle) => {
        const notificationId = Date.now(); // Use current timestamp as a unique ID
        const message = `Notification for Task Title: ${taskTitle}`;

        // Alert user of notification action
        alert(`Notification for "${taskTitle}" has been sent!`);

        // Add notification to the context with a unique ID
        addNotification({ id: notificationId, message });
    };
    // const notifyUser = (taskTitle) => {
    //     alert(`Notification for "${taskTitle}" has been sent!`);
    //     addNotification(`Notification for Task Title: ${taskTitle}`);
    // };

    const getFilteredTasks = () => {
        let filteredTasks = tasks.filter(task => {
            if (statusFilter === 'complete') return task.isCompleted;
            if (statusFilter === 'incomplete') return !task.isCompleted;
            if (statusFilter === 'dueToday') return isDueToday(task.dueDate) && !task.isCompleted;
            return true;
        });

        if (editFilter) {
            filteredTasks = filteredTasks.filter(task => task.isEdited);
        }

        return getSortedTasks(filteredTasks);
    };
    useEffect(() => {
        const userId = localStorage.getItem('user_id');
        if (socket) {
            if (role === 'admin') {
                socket.emit('joinRoom', `admin_${userId}`);
            } else {
                socket.emit('joinRoom', `user_${userId}`);
            }
    
            // Listen for notifications
            socket.on('notification', (notification) => {
                if (notification.assigned_by === userId || notification.userId === userId) {
                    addNotification(notification.message); // Show only relevant notifications
                }
            });
        }
    
        return () => {
            if (socket) {
                socket.off('notification');
            }
        };
    }, [socket, role, addNotification]);
    

    return (
        <div className="task-panel">
            <h2>My Tasks</h2>

            <div className="filter-options">
                <label>Sort By:</label>
                <select value={sortOption} onChange={(e) => setSortOption(e.target.value)}>
                    <option value="">None</option>
                    <option value="alphabetical">Alphabetical</option>
                    <option value="dueDate">Due Date & Time</option>
                </select>

                <label>Filter By Status:</label>
                <select value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)}>
                    <option value="all">All</option>
                    <option value="complete">Complete</option>
                    <option value="incomplete">Incomplete</option>
                    <option value="dueToday">Due Today</option>
                </select>

                <label>
                    <input
                        type="checkbox"
                        checked={editFilter}
                        onChange={() => setEditFilter(!editFilter)}
                    />
                    Show Edited Only
                </label>
            </div>

            {isAddingTask && (
                <div className="modal">
                    <div className="modal-content">
                        <h3>Add Task</h3>
                        <label htmlFor="task-title">Title</label>
                        <input id="task-title" type="text" value={taskTitle} onChange={(e) => setTaskTitle(e.target.value)} placeholder="Title" />

                        <label htmlFor="task-description">Description</label>
                        <textarea id="task-description" value={taskDescription} onChange={(e) => setTaskDescription(e.target.value)} placeholder="Details" />

                        <label htmlFor="task-due-date">Due Date & Time</label>
                        <input id="task-due-date" type="datetime-local" value={taskDueDate} onChange={(e) => setTaskDueDate(e.target.value)} />

                        <label htmlFor="task-reminder-time">Reminder Time</label>
                        <input id="task-reminder-time" type="datetime-local" value={taskReminderTime} onChange={(e) => setTaskReminderTime(e.target.value)} />

                        {role === 'admin' && (
                            <>
                                <label htmlFor="assigned-to">Assign To</label>
                                <select
                                    id="assigned-to"
                                    value={assignedTo}
                                    className="dropdown-admin"
                                    onChange={(e) => setAssignedTo(e.target.value)}
                                >
                                    <option value="" className="dropdown-admin-option">Select User</option>
                                    {users.map((user) => (
                                        <option key={`user-${user.id}`} value={`user-${user.id}`} className="dropdown-admin-option">
                                            User: {user.username}
                                        </option>
                                    ))}
                                    {admins.map((admin) => (
                                        <option key={`admin-${admin.id}`} value={`admin-${admin.id}`} className="dropdown-admin-option">
                                            Admin: {admin.username}
                                        </option>
                                    ))}
                                </select>
                            </>
                        )}

                        <div className="modal-buttons">
                            <button className="save-button" onClick={addTask}>Save Task</button>
                            <button className="cancel-button" onClick={() => setIsAddingTask(false)}>Cancel</button>
                        </div>
                    </div>
                </div>
            )}

            {isEditingTask && (
                <div className="modal">
                    <div className="modal-content">
                        <h3>Edit Task</h3>
                        <label htmlFor="edit-task-title">Title</label>
                        <input id="edit-task-title" type="text" value={taskTitle} onChange={(e) => setTaskTitle(e.target.value)} placeholder="Title" />

                        <label htmlFor="edit-task-description">Description</label>
                        <textarea id="edit-task-description" value={taskDescription} onChange={(e) => setTaskDescription(e.target.value)} placeholder="Details" />

                        <label htmlFor="edit-task-due-date">Due Date & Time</label>
                        <input id="edit-task-due-date" type="datetime-local" value={taskDueDate} onChange={(e) => setTaskDueDate(e.target.value)} />

                        <label htmlFor="edit-task-reminder-time">Reminder Time</label>
                        <input id="edit-task-reminder-time" type="datetime-local" value={taskReminderTime} onChange={(e) => setTaskReminderTime(e.target.value)} />

                        <div className="modal-buttons">
                            <button className="save-button" onClick={saveEditedTask}>Save Changes</button>
                            <button className="cancel-button" onClick={closeEditModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            )}

            <div className="task-list">
                {getFilteredTasks().map(task => (
                    <div
                        key={task.id}
                        className={`task-item ${isDueDatePassed(task.dueDate) && !task.isCompleted ? 'missed-task' : task.isCompleted ? 'completed-task' : ''} ${isDueToday(task.dueDate) ? 'due-today' : ''}`}>
                        <div className="task-row">
                            <input type="checkbox" checked={task.isCompleted} onChange={() => markTaskComplete(task.id)} />
                            <span className="task-title" onClick={() => toggleDetails(task.id)}>
                                {task.title || "No Title"}
                            </span>
                            <span className="task-due-date">
                                {task.dueDate ? `Due: ${convertToAustralianTime(task.dueDate)}` : "No Due Date"}
                                {isDueToday(task.dueDate) && !task.isCompleted ? (
                                    <span className="due-today-label"> (Due Today)</span>
                                ) : isDueDatePassed(task.dueDate) && !task.isCompleted ? (
                                    <span className="missed-label"> (Missed)</span>
                                ) : task.isCompleted ? (
                                    <span className="completed-label"> (Completed)</span>
                                ) : null}
                            </span>

                            {/* Notify Button for Admin when viewing tasks created for users */}
                            {role === 'admin' && (task.isUserTask || task.assigned_by === null) ? (
                                <button className="notify" onClick={() => notifyUser(task.title)}>Notify</button>
                            ) : null}

                            {/* Hide Edit and Delete for User on tasks created by Admin */}
                            {(role === 'admin' || (role === 'user' && task.assigned_by === null)) && (
                                <>
                                    <button className="edit" onClick={() => openEditModal(task)}>Edit</button>
                                    <button className="delete" onClick={() => deleteTask(task.id)}>Delete</button>
                                </>
                            )}
                        </div>

                        {task.showDetails && (
                            <div className="task-details">
                                <p><strong>Description:</strong> {task.description || "No Description"}</p>
                                <p><strong>Reminder Time:</strong> {task.reminderTime ? convertToAustralianTime(task.reminderTime) : "No Reminder Set"}</p>
                                <p><strong>Assigned To:</strong> {task.assignedUsername || "Unassigned"}</p>
                            </div>
                        )}
                    </div>
                ))}
            </div>

            <button className="floating-add-button" onClick={() => setIsAddingTask(true)}>
                +
            </button>
        </div>
    );
};

export default TaskPanel;
